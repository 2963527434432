#wrapper_login {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 0 1rem;

	.logo {
		max-width: 42rem;
		width: 100%;
		padding: 0 6rem 2rem 6rem;
	}

	.content {
		max-width: 42rem;
		width: 100%;

		&.panel {
			border: 1px solid $color-grey-0;
			padding: 2.5rem;
			border-radius: $radius-default;
			box-shadow: $box-shadow-1;
		}

		.tr-login_forgotpass_header,
		.tr-login_request_header {
			font-family: $font-heading;
			font-size: 2.4rem;
			text-transform: uppercase;
			margin: 0 0 1rem 0;
			line-height: 1;
			font-weight: 500;
			display: block;
		}

		.tr-login_forgotpass_askforinput,
		.tr-login_request_askforinput {
			font-size: 1.4rem;
			display: block;
			margin: 0 0 1rem 0;

			a {
				text-decoration: underline;
			}
		}

		.form__block {
			display: flex;
			flex-direction: column;

			.form__control {
				display: flex;
				flex-direction: column;
				margin: 0 0 1rem 0;

				label {
					margin: 0 0 0 1.5rem;
					color: $color-text-grey-0;
					font-family: $font-heading;
					text-transform: uppercase;
					font-size: 1.4rem;

					&.error {
						color: $color-red-1;
					}
				}

				input {
					border: 1px solid $color-grey-0;
					font-size: 2rem;
					padding: 1rem 2rem;
					border-radius: $radius-default;
					font-family: $font-heading;
					color: $color-black-2;
					width: 100%;

					&:focus {
						border-color: $color-gold-3;
					}
				}

				&.submit {
					margin: 0;

					input {
						background-color: $color-gold-3;
						color: $color-black-2;
						font-family: $font-heading;
						text-transform: uppercase;
						border: none;
						margin: 1rem 0 0 0;
						transition: 0.3s $cubic-bezier-1 all;

						&:hover {
							background-color: $color-gold-2;
						}
					}
				}

				.radio__group {
					display: flex;
					flex-direction: column;

					label {
						display: flex;

						input {
							width: inherit;
							margin: 0 1rem 0 0;
						}
					}
				}
			}

			.inlog_form {
				border: 1px solid $color-grey-0;
				padding: 2.5rem;
				border-radius: $radius-default;
				box-shadow: $box-shadow-1;
			}

			.login__links {
				color: $color-text-grey-0;
				margin: 1rem 0 0 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: 1.2rem;

				a {
					text-decoration: underline;
				}
			}
		}

		#taalkeuze {
			display: flex;
			justify-content: center;
			font-family: $font-heading;
			text-transform: uppercase;
			font-size: 1.6rem;
			font-weight: 600;

			span {
				margin: 1rem;
			}
		}
	}

	#klantlogin_aanvragen {
		color: $color-text-grey-0;
		margin: 1rem 0 0 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 1.2rem;
		text-decoration: underline;
	}
}

.footer {
	display: flex;
	justify-content: center;
	font-size: 1.2rem;
	color: $color-text-grey-0;

	a {
		margin-left: 0.5rem;
	}
}

@media(max-width: 420px) {
	#wrapper_login {
		justify-content: flex-start;
		margin-top: 2rem;
	}
}

#maintenance-notification {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 0 4rem 0;
	max-width: 42rem;

	h3 {
		margin: 0;

		span {
			font-size: 3rem;
			font-weight: 600;
		}

		&:after {
			content: none;
		}
	}

	span {
		font-size: 1.8rem;
		font-family: $font-heading;
		font-weight: 300;
	}
}
