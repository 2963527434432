.header {
	height: $height-header + 1rem;
	border: none;
	background-color: $color-main-light-0;

	&::before {
		content: ' ';
		background-color: $color-black-1;
		width: 100%;
		height: $height-header-bar;
		display: block;
	}

	.header__wrapper {
		display: flex;
		justify-content: space-between;

		.header__logo {
			img {
				height: $height-header;
				padding: 1.6rem 0 1.6rem 4rem;
			}
		}

		#portal-title {
			display: none;
		}

		.header__menu {
			display: flex;
			padding: 0 3.2rem;
			height: $height-header;

			.menu__main {
				border: inherit;
				background: inherit;
				font-weight: inherit;
				color: inherit;

				display: flex;
				flex-direction: row;
				line-height: $height-header;
				height: $height-header;
				padding: 0 1.6rem;

				.portal-control-login, .tr-portal_klantkeuze {
					display: none;
				}

				.menu__dropdown {
					display: flex;
					align-items: center;
					height: $height-header;

					&.fg-menu-open {
						background-color: $color-black-1;
						color: white;
						box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.5);
						border-bottom: 2px solid $color-main-light-0;

						&::after {
							@include triangle(up, white, 0.5rem);
						}
					}

					&::after {
						@include triangle(down, $color-black-1, 0.5rem);
						margin: 0 0 0 1rem;
					}

					span {
						margin: 0 0.5rem;
					}
				}
			}

			.menu__auth, .menu__dropdown {
				font-family: Oswald, sans-serif;
				font-weight: 400;
				font-size: 2rem;
				height: auto;
				line-height: $height-header;
				text-transform: uppercase;
				padding: 0 1.6rem;
				cursor: pointer;
				border: none;
				color: $color-black-1;

				&:hover {
					background-color: $color-main-light-1;
				}

				&.ui-state-active {
					background: none;
				}

				a {
					color: $color-black-1;
					text-decoration: none;
				}
			}
		}
	}
}

#portal-overlay {
	overflow: auto;
	border: none;
}

#portal-content-wrapper {
	display: flex;
	flex-direction: row;
	width: 100%;
}

#portal-content {
	width: 100%;
	height: 100vh;
	overflow-y: auto;
}

#portal-promos {
	width: 32.3rem;
	position: fixed;
	right: 0;
}

#portal-wrap {
	display: flex;
	height: 100vh;
}

#portal-notif {
	width: 300px;
	right: 0;
	top: 0;
	position: absolute;
	z-index: 3;
}

#portal-notif .portal-notification {
	min-height: 50px;
	right: 3px;
	display: none;
	margin-bottom: 1px;
}

#portal-foot {
	height: $height-footer;
	text-align: right;
	padding: 0 .5em;
	border: 1px solid #E5E1D9;
	background-image: url(/public/images/bg-trans-white.png);
	font-size: 0.8em;
}


body#development #portal-foot {
	background-image: none;
	background-color: lime;
}

body#demo-dev #portal-foot {
	background-image: none;
	background-color: olive
}

body#demo-prod #portal-foot {
	background-image: none;
	background-color: tomato
}


#portal-title {
	font-size: 2em;
	padding-left: 85px;
	background: url("../../../../public/images/portal-logo-charles-kl.png") no-repeat scroll 10px center transparent;
	line-height: 65px;
	color: #FEE8A0;
}

#portal-overlay {
	bottom: 1.7em;
	background: url(/public/images/loader.gif) no-repeat scroll center center #EEEEEE !important;
	width: auto !important;
	z-index: 10;
	cursor: progress;
}

#portal-modal {
	display: none;
}

#portal-null {
	display: none;
}

.seizoensproduct_zomer {
	background: #FFFFFF url(images/seizoensproduct-zomer.gif) no-repeat center center;
	display: block;
	width: 100%;
	height: 100%;
	color: #FFFFFF;
}

.seizoensproduct_winter {
	background: #FFFFFF url(images/seizoensproduct-winter.gif) no-repeat center center;
	display: block;
	width: 100%;
	height: 100%;
	color: #FFFFFF;
}


.formBox {
	border: 1px solid #E5E1D9;
	background: transparent url('../../../../public/images/bg-trans-white.png') repeat top left;
	padding: 20px;
	width: 400px;
	margin: 20px auto;
	text-align: center
}

.formBox input, .formBox textarea {
	border: 1px solid #E5E1D9;
	padding: .4em;
	margin: 5px 0;
}

.cleardiv {
	height: 0 !important;
	clear: both !important;
	float: none !important;
	margin: 0 !important;
	padding: 0 !important;
}

.faq_question {
	background: url("../../../resources/images/right_z.png") no-repeat scroll left center transparent;
	cursor: pointer;
	font-weight: bold;
	padding-left: 1.7em;
}

.faq_answer {
	padding-left: 1.7em;
}

.faq_question.open {
	background: url("../../../resources/images/down_z.png") no-repeat scroll left center transparent;
}

.faq_question:hover {
	text-decoration: underline;
}

.faq-inhoud ul {
	list-style: disc outside;
	margin-left: 1.3em;
}

.faq-inhoud h2 {
	margin-top: 1.2em;
	font-size: 1.5em;
}

.faq_qna {
	margin: .2em;
	padding: .2em .4em;
}

.faq .faq_qna {
	border: 1px solid #CCC;
}


#portal-contactform fieldset {
	padding: 1em
}

#portal-contactform label {
	display: block;
}

#portal-contactform div {
	margin: .5em 0
}

#portal-contactform #portal_comment {
	width: 100%
}

#portal-contactform legend {
	font-size: 1.2em
}

.portal_form_keuze {
	margin: .2em;
	padding: .2em .4em;
}

.portal_form_keuze_label {
	font-size: 1.2em;
}

textarea {
	width: 380px;
}

.userselect .select_naam {
	font-weight: bold;
	/*text-decoration: underline;*/
}

.userselect .select_adres,
.userselect .select_info {
	font-size: 0.8em;
}


#faq-sluit-knop {
	position: absolute;
	right: 1em;
	bottom: 1em;
}

#portal-faq-terug {
	position: absolute;
	left: 1em;
	bottom: 3em;
}

#portal-faq-sluit {
	position: absolute;
	right: 1em;
	bottom: 3em;
}

.positionHelper {
	z-index: 99;
}

#faq-formulier-knop {
	color: #722608;
	font-weight: bold;
}

.ui-buttonset .ui-button.ui-priority-secondary {
	margin-right: -.1em;
}

a:active, a:focus {
	outline: none;
}

.box_shadow, .ui-tooltip-shadow {
	-webkit-box-shadow: 0px 0px 3px 0px #000 !important; /* Saf3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
	box-shadow: 0px 0px 3px 0px #000 !important; /* Opera 10.5, IE9, FF4+, Chrome 6+, iOS 5 */
	-moz-box-shadow: 0px 0px 3px 0px #000 !important;
}

.box_shadow,
.ui-tooltip-shadow .ui-tooltip-titlebar,
.ui-tooltip-shadow .ui-tooltip-content {
	filter: progid:DXImageTransform.Microsoft.Shadow(Color='gray', Direction=135, Strength=3);
	-ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Color='gray', Direction=135, Strength=3)";

	_margin-bottom: -3px; /* IE6 */
}

input[type='number'] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.paasei {
	display: none;
}

body.toonpasen .paasei {
	display: inline;
	opacity: .4;
	cursor: pointer;
}

body.toonpasen .paasei:hover {
	opacity: 1;
}

body.toonpasen .paasei.paasei2, body.toonpasen .paasei.paasei3 {
	float: right;
}

.paasei img {
	-webkit-animation: myanim 5s infinite; /* Chrome, Opera 15+, Safari 5+ */
	animation: myanim 5s infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

body.toonpasen.paasei1 .paasei.paasei1 img, body.toonpasen.paasei2 .paasei.paasei2 img, body.toonpasen.paasei3 .paasei.paasei3 img, body.toonpasen.paasei4 .paasei.paasei4 img {
	-webkit-animation: none; /* Chrome, Opera 15+, Safari 5+ */
	animation: none; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@keyframes myanim {
	0% {
		transform: rotate(0deg)
	}
	3% {
		transform: rotate(-4deg)
	}
	6% {
		transform: rotate(4deg)
	}
	9% {
		transform: rotate(-1deg)
	}
	12% {
		transform: rotate(1deg)
	}
	15% {
		transform: rotate(0deg)
	}
	50% {
		transform: translateY(0)
	}
	53% {
		transform: translateY(-3px)
	}
	56% {
		transform: translateY(0)
	}
	59% {
		transform: translateY(-3px)
	}
	62% {
		transform: translateY(0)
	}
	100% {
		transform: rotate(0deg) translateY(0);
	}
}

@-webkit-keyframes myanim {
	0% {
		transform: rotate(0deg)
	}
	3% {
		transform: rotate(-4deg)
	}
	6% {
		transform: rotate(4deg)
	}
	9% {
		transform: rotate(-1deg)
	}
	12% {
		transform: rotate(1deg)
	}
	15% {
		transform: rotate(0deg)
	}
	50% {
		transform: translateY(0)
	}
	53% {
		transform: translateY(-3px)
	}
	56% {
		transform: translateY(0)
	}
	59% {
		transform: translateY(-3px)
	}
	62% {
		transform: translateY(0)
	}
	100% {
		transform: rotate(0deg) translateY(0);
	}
}

#cboxCurrent {
	display: none !important;
}

#cboxContent button {
	opacity: .3;
}

.b-iframe {
	min-width: 1200px !important;
	min-height: 700px !important;
}

.b-first {
	background-color: #51BD4D !important;
	border-bottom-color: #51BD4D !important;
	background-image: none !important;
	font-size: 5px !important;
	line-height: 5px !important;
	height: 20px !important;
}

.b-second {
	background-color: #946923 !important;
	border-bottom-color: #946923 !important;
	background-image: none !important;
	font-size: 5px !important;
	line-height: 5px !important;
}

.b-third {
	background-color: #944626 !important;
	border-bottom-color: #944626 !important;
	background-image: none !important;
	font-size: 5px !important;
	line-height: 5px !important;
}

.b-fourth {
	background-color: #660000 !important;
	border-bottom-color: #660000 !important;
	background-image: none !important;
	font-size: 5px !important;
	line-height: 5px !important;

}

.tool {
	cursor: help;
}

.bar-text {
	font-size: 11px;
	text-align: right;
	float: left;
	width: 25%;
}

.bar-wrapper {
	float: left;
	width: 75%;
}

#bar-wrapper {
	float: left;
	width: 60%;
	height: 15px;
}

#beurs-totals {
	float: left;
	width: 40%;
}

#progress-indicator {
	width: 100%;
	border: #000000 1px solid;
	height: 20px;
}

#legend-first {
	width: 12.6%;
}

#legend-second {
	width: 16.7%;
}

#legend-third {
	width: 50%;
}

#legend-fourth {
	width: 16.7%;
}

.legend {
	float: left;
	height: 15px;
	text-align: left;
	font-size: 12px;
}

.bar {
	float: left;
	height: 20px;
	text-align: center;
	font-size: 12px;
	line-height: 20px;
}

#bar-first {
	width: 16.6%;
	border-right: #000000 solid 1px;
}

#bar-second {
	width: 16.7%;
	border-right: #000000 solid 1px;
}

#bar-third {
	width: 50%;
	border-right: #000000 solid 1px;
}

#bar-fourth {
	width: 15.0%;
}

#progress-bar-wrapper {
	width: 100%;
	margin-top: -20px;
}

#progress-bar {
	height: 19px;
	padding: 0;
	margin: 0;
}

#beurs-totals {
	font-size: 12px;
}

#totals-wrapper {
	float: right;
}

#discount-total {
	font-size: 15px;
	text-decoration: underline;
	color: #51BD4D;
}

.clearfix {
	clear: both;
}

.legend_img {
	margin-right: 5px;
}

#information_beurs img {
	position: absolute;
	width: 140px;
	top: -8px;
	border: 4px solid #51BD4D;
	right: 0;
}

.header_table td {
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
}

.body_table td {
	border-right: 1px solid #000;
}

.ui-progressbar {
	width: 100%;
}

#popitup .b-iframe html {
	overflow: hidden
}

.webapp__container {
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
}

.webapp__iframe {
	height: 100%;
	width: 100%;
	//min-width: 100%;
	//position: absolute;
	-webkit-overflow-scrolling: touch;
}

#portal-content {
	-webkit-overflow-scrolling: touch;
}

@media (max-width: 650px) {
	#portal-wrap {
		flex-direction: column;
	}
}
