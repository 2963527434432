@import '../../../variables';

.dashboard {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4rem 0;

	.dashboard-panel {
		width: 100%;
		//border: 1px solid $color-grey-0;
		border-radius: $radius-default;
		margin: 0;
		display: flex;
		flex-direction: column;
		align-items: center;

		& > div {
			width: 100%;
			max-width: 100rem;
			padding-left: 2rem;
			padding-right: 2rem;
		}

		&:last-of-type {
			&::after {
				content: none;
			}
		}

		&.widget-promo {
			display: none !important;
		}

		.panel__text {
			font-size: 1.4rem;
			font-family: $font-main;
			margin: 2rem 0;
			font-weight: 400;
		}
	}
}

.widget-placeholder { border: 2px dashed #999; margin: 10px 5px 0 5px;}

div.ui-dialog-buttons{
    top: 10px !important;
}