@mixin triangle($direction, $color, $size) {
	width: 0;
	height: 0;
	content: ' ';

	border-left: $size solid transparent;
	border-right: $size solid transparent;
	border-top: $size solid transparent;
	border-bottom: $size solid transparent;

	@if $direction == 'left' {
		border-right-color: $color;
		border-left-width: 0;
	}

	@if $direction == 'up' {
		border-bottom-color: $color;
		border-top-width: 0;
	}

	@if $direction == 'right' {
		border-left-color: $color;
		border-right-width: 0;
	}

	@if $direction == 'down' {
		border-top-color: $color;
		border-bottom-width: 0;
	}
}