.fg-menu-container {
	border: none;
	padding: 0;
	border-radius: 0;
	width: auto;
	box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.5);

	.userselect {

		.userselect__item {

			background-color: $color-black-3;
			font-family: $font-heading;
			font-weight: 400;
			padding: 1.6rem;
			font-size: 1.4rem;

			display: flex;

			&:hover {
				background-color: $color-black-1;
			}

			a {
				color: white;
				border: none;
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;

				.userselect__text {

					.userselect__header {
						font-size: 1.8rem;

						.userselect__number, .userselect__name {
							display: inline;
						}

						.userselect__number {
							margin: 0 0.4rem 0 0;
						}
					}

					.userselect__address {
						font-weight: 300;
					}

					.userselect__info {
						display: none;
					}
				}

				.userselect__open-order {
					width: 2.4rem;
					height: 2.4rem;
					min-width: 2.4rem;
					min-height: 2.4rem;
					border-radius: 10rem;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: $color-main-light-0;
					color: $color-black-0;
					font-weight: 900;
				}

				&.ui-state-hover {
					border: none;
					background: none;
				}
			}
		}
	}
}

#klantnummerdiv {
	display: flex;
	justify-content: center;
}

.klantnummer_list {
	max-width: 120rem;

	tbody {
		tr {
			cursor: pointer;

			&.hover {
				background-color: inherit;
			}

			td {
				&.actieve_klant {
					font-weight: 400;
				}
			}
		}
	}
}

.klant_selectie_nr_header-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	h3 {
		margin: 4rem 0 4rem 0;
	}

	h2 {
		font-family: $font-heading;
		text-transform: uppercase;
		font-size: 1.8rem;
		font-weight: 400;
		margin: 0 0 2rem 0;
	}
}
