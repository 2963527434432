@import './variables.scss';

body {
	color: $color-black-2;
	font-family: $font-main;
}

a {
	text-decoration: inherit;
	color: inherit;
}

b {
	font-weight: inherit;
}

.fa-file-pdf {
	color: $color-pdf;
}

h3 {
	font-family: $font-heading;
	text-transform: uppercase;
	font-size: 3.4rem;
	font-weight: 600;
	display: inline-flex;
	flex-direction: column;
	margin-bottom: 2rem;
	color: $color-black-2;

	&::after {
		content: '';
		height: 0.5rem;
		width: calc(100% + 4rem);
		background-color: $color-gold-3;
		transform: rotate(-1deg);
		margin: 0 0 0 -2rem;
	}
}

.eob-badge {
	color: white;
	font-size: 1.2rem;
	margin: 0 0.5rem 0 0;
	padding: 0.5rem 0.8rem;
	text-transform: uppercase;
	border-radius: $radius-badges;
	cursor: default;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $color-eob;
}
