.bestel_widget_table_title {
	margin-top: 1em
}

.bestel_widget_table {
	width: 100%
}

.bestel_widget_leverdatum,
.bestel_widget_referentie,
.bestel_widget_document {
	padding: 1px 3px
}

.bestel_lijst_pdf {
	width: 1.2em;
	height: 1.2em;
	margin-left: 3px
}

.bestel_widget .ui-button-icon-only .ui-button-text,
.ui-button-icons-only .ui-button-text {
	display: none;
}

.bestel_widget .lever_done,
.bestel_widget .lever_done td {
	color: #999
}

.dashboard-panel__table tbody tr,
.dashboard-panel__table tbody tr td {
	border-bottom: 1px solid #E5E1D9;
}

table.dashboard-panel__table {
	tbody {
		tr {
			&.delivery-today {
				// TODO
			}

			&.delivery-done {
				// TODO
			}
		}
	}
}
