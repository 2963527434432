.statistics {
	padding: 15px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	animation: fade 2s;
	position: relative;
}

.panel {
	margin: 0;
	padding: 0.8rem;
}

.panel-sm {
	width: 100%/3;
}

.panel-md {
	width: 50%;
}

.panel-lg {
	width: 100%;
}

.panel__wrapper {
	background-color: $color-grey-0;
	color: $color-main-dark-0;
	overflow: hidden;
	width: 100%;
}

.panel__title {
	background-color: $color-main-dark-0;
	padding: 1.5rem;
	color: #FFF;
	font-size: 2.4rem;
	font-family: $font-heading;
	text-transform: uppercase;
}

.panel__content {
	padding: 1.5rem;

	&.new {
		padding: 0;
	}
}

.dark {
	.panel__title {
		background-color: $color-black-1;
		font-size: 2.4rem;
		border-left: 1rem solid $color-gold-3;
	}
}

.charts__wrapper {
	display: flex;


	.charts__filter {
		min-width: 10rem;
		height: 100%;
		background-color: $color-grey-0;
	}

	.charts__chart {
		min-width: 500px;
	}
}

.choice-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.choice {
	display: flex;
	flex-direction: column;
	width: 800px;
	justify-content: space-around;
	align-items: center;

	h2 {
		font-size: 5rem;
		font-family: $font-heading;
	}

	a {
		text-decoration: none;
		color: inherit;
		transform: scale(0.5);

		&:visited {
			color: inherit;
		}


		.tile-wrapper {
			border: 2px solid black;
		}
	}
}

// Be more specific because these overwrite my styles in another file :'(
// input[type=radio] {
// 	position: absolute;
// 	visibility: hidden;
// 	display: none;
// }

// label {
// 	color: $color-black-1;
// 	display: table-cell;
// 	cursor: pointer;
// 	font-weight: bold;
// 	padding: 5px 20px;
// }

// input[type=radio]:checked + label{
// 	color: white;
// 	background: $color-black-1;
// }

// label + input[type=radio] + label {
// 	border-left: solid 3px $color-black-1;
// }

.radio-group {
	border: solid 3px $color-black-1;
	display: inline-block;
	margin: 20px 20px 5px 20px;
	border-radius: 10px;
	overflow: hidden;
}

//select box
.select-chart {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	outline: 0;
	box-shadow: none;
	border: 0 !important;
	background: #2c3e50;
	background-image: none;
}
.select-chart {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: .5em;
	color: #fff;
	cursor: pointer;
}
.select-chart::-ms-expand {
	display: none;
}