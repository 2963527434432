.dashboard {
	overflow: auto;
	flex: 1;
	display: flex;
	flex-direction: column;

	.panel__text {
		&.more {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: flex-end;
			flex: 1;

			a {
				padding: 0.8rem 2.5rem;
				font-family: $font-heading;
				text-transform: uppercase;
				text-decoration: none;
				font-size: 1.6rem;
				background-color: #21211f;
				color: white;
				border-radius: 2.5rem;
				font-weight: 500;

				span {

				}
			}
		}
	}
}
