.komendtor {
	overflow: auto;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.komend_row {
	border-bottom: 1px solid #CCC;
}

.komend_stuks {
	font-weight: 400;
}

.zebra tbody tr:nth-child(odd) {
	background-color: #EEE;
}
