.portal__sidenav {
	height: 100vh;
	min-width: 36rem;
	background-color: $color-black-1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow: hidden;
	overflow-y: auto;
	z-index: 999;

	a {
		color: inherit;
		text-decoration: inherit;
	}

	.sidenav__brand {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin: 2rem 0 1rem 0;
		padding: 0 4rem;

		a {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		img {
			height: 6rem;
		}

		.sidenav__collapse {
			color: white;
			font-size: 1.6rem;
			cursor: pointer;
			width: 3.6rem;
			justify-content: center;
			display: flex;

			.hamburger-menu {
				display: none;
			}

			&::before {
				content: '\f100';
				font-family: 'Font Awesome 5 Free';
				font-weight: 600;
			}

		}
	}

	.sidenav__top {
		height: 100%;
		display: flex;
		flex-direction: column;

		&::before {
			content: '';
			border-top: 5px solid $color-gold-3;
			width: 100%;
			transform: rotate(-3deg);
			margin: 2rem 0 3rem 0;
		}

		.sidenav__item {
			color: white;
			font-size: 1.6rem;
			font-family: $font-heading;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin: 0 0 1rem 0;
			padding: 0rem 4rem;
			transition: 0.1s $cubic-bezier-1 color;
			cursor: pointer;

			&:hover {
				color: $color-gold-3;
			}

			&.sidenav__item--user,
			&.sidenav__item--customer,
			&.sidenav__item--logout {
				margin: 0;
			}

			&.sidenav__item--logout {
				font-size: 1.4rem;
			}			

			.item__description {
				display: flex;
				align-items: center;
				height: 3.6rem;
				margin: 0 1rem 0 0;
				width: 100%;

				.fas, .far, .fal {
					margin: 0 1rem 0 0;
					min-width: 2.4rem;
					width: 2.4rem;
					display: flex;
					justify-content: center;
				}
			}

			.icon__right {
				min-width: 3.6rem;
				width: 3.6rem;
				height: 3.6rem;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		ul {
			margin: 1rem 0 0 0;
			padding: 0rem 4rem;

			.nav__item {
				font-family: $font-heading;
				text-transform: uppercase;
				font-weight: 400;
				color: white;
				font-size: 2rem;
				border: none;
				padding: 0.5rem 0;
				cursor: pointer;
				transition: 0.1s $cubic-bezier-1 color;
				
				a {
					color: inherit;
					text-decoration: none;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				.nav__icon {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 3.6rem;
					height: 3.6rem;
					font-size: 1.6rem;
					background-color: $color-black-1;
					border-radius: $radius-default;
					transition: 0.1s $cubic-bezier-1 background-color, 0.1s $cubic-bezier-1 color;
					position: relative;
				}

				&.active {
					color: $color-gold-1;

					.nav__icon {
						background-color: $color-black-2;
					}
				}

				&:hover {
					color: $color-gold-3;
				}

				&.nav__item--new {
					.nav__icon {
						&::after {
							content: '';
							background-color: $color-red-1;
							width: 1rem;
							height: 1rem;
							position: absolute;
							border-radius: 1rem;
							top: 0;
							right: 0;
						}
					}	
				}
			}
		}
	}

	.sidenav__bottom {
		padding: 0 4rem 2rem 4rem;
		color: white;
		font-size: 1.4rem;
		font-family: $font-main;
		font-weight: 600;

		.sidenav__contact,
		.sidenav__links {

			.fas, .far, .fal {
				margin: 0 1rem 0 0;
				font-size: 1.4rem;
			}

			a {
				color: white;
				text-decoration: underline;
			}
		}

		.contact__address {
			margin: 2rem 0;
		}

		.contact__details {
			margin: 2rem 0;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.contact--email {
				text-decoration: none;
			}

			.contact--message {
				text-decoration: none;
				border-radius: $radius-default;
				background-color: $color-black-2;
				padding: 1rem 2rem;
				margin: 2rem 0 0 0rem;
				font-family: $font-heading;
				text-transform: uppercase;
				font-weight: 500;
			}
		}
	}

	.sidenav__small {
		display: none;
	}
}

.portal__sidenav--collapse {
	width: 6rem;
	min-width: 6rem;

	.sidenav__brand {
		flex-direction: column;
		align-items: center;
		padding: 0;

		img {
			width: calc(100% - 1rem);
			height: auto;
		}

		.sidenav__collapse {
			margin: 1rem 0 0 0;

			&::before {
				content: '\f101';
			}
		}
	}

	.sidenav__bottom {
		display: none;
	}

	.sidenav__top {

		&::before {
			margin: 0.5rem 0 2rem 0;
		}

		.sidenav__item {
			flex-direction: column;
			padding: 0;
			margin: 0;

			.item__description {
				display: none;
			}

			&.sidenav__item--logout {
				margin-top: 0;
			}
		}

		.nav__items {
			padding: 0;
			display: flex;
			flex-direction: column;
			align-items: center;

			.nav__item {
				margin: 0 0 1rem 0;

				.nav__link {
					span {
						visibility: hidden;
						opacity: 0;
						width: 0;
						height: 0;
					}

					.nav__icon {
						font-size: 1.8rem;
					}
				}

				&:hover {

					span {
						width: inherit;
						height: inherit;
						visibility: visible;
						opacity: 1;
						position: absolute;
						left: 7rem;
						background-color: $color-black-1;
						padding: 0.8rem 1.4rem;
						font-size: 1.4rem;
						border-radius: 2.5rem;
						transition: opacity 0.1s 0.5s linear;
					}
				}
			}
		}
	}
}

@media (max-width: 650px) {
	.portal__sidenav {
		width: 100%;
		height: 100vh;
		min-height: 100vh;
		transition: 0.3s $cubic-bezier-1 all;

		.sidenav__brand {
			flex-direction: row-reverse;
			justify-content: space-evenly;
			padding: 1rem 0;
			margin: 0;

			.spacer {
				width: 3.6rem;
			}

			img {
				height: 4rem;
				width: auto;
			}

			.sidenav__collapse {
				margin-top: 0;

				&::before,
				&::after {
					content: none;
				}

				.hamburger-menu {
					display: flex;
					flex-direction: column;
					align-items: center;
					height: 2.4rem;
					width: 3.1rem;
					position: relative;

					&> span {
						position: absolute;
						width: 100%;
						height: 0.2rem;
						background-color: white;
						transition: 0.3s $cubic-bezier-1 all;

						&.line--1 {
							transform: rotate(45deg);
							top: 1.1rem;
						}

						&.line--2 {
							width: 0%;
							top: 1.1rem;
						}

						&.line--3 {
							transform: rotate(-45deg);
							top: 1.1rem;
						}
					}
				}
			}
		}
	}

	.portal__sidenav--collapse {
		height: 6rem;
		min-height: 6rem;

		.sidenav__brand {
			.sidenav__collapse {
				.hamburger-menu {
					&> span {
						&.line--1 {
							top: 0.4rem;
							transform: none;
						}

						&.line--2 {
							width: 100%;
						}

						&.line--3 {
							top: 1.8rem;
							transform: none;
						}
					}
				}
			}
		}

		.sidenav__top,
		.sidenav__bottom {
			display: none;
		}
	}
}
