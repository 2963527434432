.customer-settings {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 4rem 0;

	& > * {
		max-width: 100rem;
	}

	h1 {
		font-family: $font-heading;
		text-transform: uppercase;
		font-size: 4rem;
		font-weight: 600;
		display: flex;
		flex-direction: column;
		margin: 1rem 0;

		&::after {
			content: '';
			height: 0.5rem;
			width: calc(100% + 4rem);
			background-color: $color-gold-3;
			transform: rotate(-1deg);
			margin: 0 0 0 -2rem;
		}
	}

	img {
		width: 3rem;
		height: 3rem;
	}

	fieldset {
		border: 1px solid #999;
		margin: 0.5em;
		padding: 1em;
	}

	.settings__tabs {
		.tabs__list {
			display: flex;
			margin: 2rem 0.5rem;
			justify-content: center;

			.list__item {
				padding: 1rem 0;
				margin: 0rem 2rem;

				.tab__item {
					font-size: 2rem;
					font-family: Oswald, sans-serif;
					font-weight: 400;
					text-transform: uppercase;
					display: flex;
					flex-direction: column;
					color: $color-black-2;
				}

				&.ui-tabs-active {
					.tab__item::after {
						content: "";
						background-color: $color-gold-3;
						width: calc(100% + 2rem);
						margin: 0.5rem 0 0 -1rem;
						transform: rotate(-1deg);
						height: 0.5rem;
					}
				}
			}
		}

		.tab__content {
			font-size: 1.4rem;

			.tab__info {
				font-family: $font-main;
				margin: 0 0 2rem 0;
			}

			input {
				border: 1px solid $color-grey-1;
				border-radius: $radius-default;
				padding: 1rem 2rem;
				font-size: 1.8rem;
				font-family: Oswald;
				color: $color-black-2;
				width: 100%;
				max-width: 48rem;

				&:focus {
					border-color: $color-gold-3;
				}

				&[disabled="disabled"] {
					color: $color-text-grey-0;
					background-color: $color-grey-0;
					cursor: not-allowed;
				}

				&[type="submit"], &[type="reset"] {
					background-color: $color-black-2;
					text-transform: uppercase;
					color: white;
					border: none;
					cursor: pointer;
					margin: 2rem 2rem 2rem 0;
				}
			}
			
			button {
				background-color: $color-black-2;
				text-transform: uppercase;
				color: white;
				margin-right: 2rem;
				border: none;
				border-radius: $radius-default;
				padding: 1rem 2rem;
				font-size: 1.8rem;
				font-family: $font-heading;
			}

			label {
				font-family: $font-heading;
				text-transform: uppercase;
				font-size: 1.4rem;
				margin: 0 0 0 2rem;
				color: $color-text-grey-0;
				display: flex;
			}

			h3 {
				font-weight: 500;
				font-size: 2.8rem;
				margin-left: 2rem;
			}

			.form__actions {
				display: flex;
			}

			.form__group {
				margin: 0 0 2rem 0;

				&.required {
					span {
						display: flex;

						&::after {
							content: '\f069';
							font-family: $font-awesome;
							font-weight: 600;
							color: $color-red-1;
							font-size: 0.8rem;
							margin: 0 0 0 0.2rem;
						}
					}
				}
			}

			&.settings__contact {
				.contact__section {
					margin: 4rem 0;
				}

				.contact__addresses {
					display: flex;

					.contact__address {
						width: 100%;

						.form__group {
							margin: 0 2rem 1rem 0;
						}
					}
				}

				.contact__details {
					th {
						padding: 2rem;
					}
				}

				.transfer__button {
					display: inline;
					margin: 0 0 0 1rem;
					font-size: 1.6rem;

					.xferbutton {
						span {
							//display: none;
						}

						&::after {
							content: '\f0ec';
							font-family: $font-awesome;
							font-weight: 600;
						}
					}
				}
			}

			&.settings__vacation {
				.detail {
					display: flex;
					flex-direction: column;
				}

				input {
					width: inherit;
				}

				.vacation__multiple-info {
					margin: 2rem 0 1rem 0;
				}

				.vacation__customer-id {
					margin: 0;

					input {
						margin: 0 1rem 0 0;
					}
				}
			}

			&.settings__communications {
				.settings__title {
					margin-top: 4rem;
					font-size: 2rem;

					&::after {
						content: none;
					}
				}

				.help__icon {
					border: none;

					&::after {
						content: '\f05a';
						font-family: $font-awesome;
						font-weight: 600;
					}
				}

				table {
					td {
						label {
							font-family: $font-main;
							text-transform: none;
						}
					}
				}
			}

			&.settings__suggested-prices {
				.manual {
					background-color: $color-black-2;
					text-transform: uppercase;
					color: white;
					border: none;
					border-radius: $radius-default;
					padding: 1rem 2rem;
					font-size: 1.8rem;
					font-family: $font-heading;
				}

				input {
					width: inherit;

				}

				strong {
					margin: 0 0.5rem;
				}

				.checkbox__label {
					margin: 2rem;

					input {
						margin: 0 1rem 0 0;
					}
				}
			}
		}
	}
}

.gegevens_kopieren {
	font-size: 0.8em !important;
	text-align: center;
	margin: 0.4em 0;
	border-top: 1px solid #999;
}

.readonly {
	background-color: $color-grey-1;
}

.comm_keuze {
	text-align: center;
	width: 7em;
	padding: 0 0.3em;
}

.comm_event_title {
	font-size: 1.1em;
	text-decoration: underline;
}

.comm_event_info {
	margin-bottom: 0.3em;
}

.comm_info {
	margin-bottom: 1em;
}

.comm_even {
	background-color: #eee;
}

.comm_event td {
	padding: 0.4em 0.5em;
}

.comm_events {
	margin-top: 0.5em;
}

.adviesprijs_table tbody tr td {
	border: 1px solid #e5e1d9;
	border-width: 1px 0 0 1px;
	padding: 0 5px;
	text-align: center;
}

.advies_custom,
.advies_default {
	display: none;
}

.adviesprijzen_custom .advies_custom {
	display: inline;
}

.adviesprijzen_default .advies_default {
	display: inline;
}

.adviesprijs_tabel {
	float: left;
	margin: 1em;
	margin-left: 0;
	max-width: 800px;
	min-height: 130px;
}

.adviesprijs_tabel th {
	padding: 0 3px;
	text-align: center;
}

.adviesprijs_tabel table {
	border: 1px solid #666;
	border-top: 0;
	min-width: 300px;
}

.adviesprijs_tabel table caption h3 {
	margin-bottom: 0;
}

.adviesprijs_table tbody tr td.rijname {
	font-size: 1.1em;
	padding: 0.3em 0;
	text-indent: 0.3em;
	white-space: nowrap;
	border-left: 0;
	text-align: left;
	width: 150px;
}

input.custommarge {
	text-align: right;
	border: 1px inset #666666;
	border-radius: 6px 6px 6px 6px;
	font-size: 1.1em !important;
	font-weight: bold;
	padding: 0.1em 0;
	width: 3.6em;
}

.adviesprijs_tabel tr > *:nth-child(2n) {
	background-color: #eeeeee;
}

#adviesprijs-tab {
	position: relative;
}

.advies_copy {
	margin: 0.3em 0;
}

tr.sublogin_row td {
	vertical-align: top;
	padding: 0.5em;
}

tr.sublogin_row:nth-of-type(2n) {
	background-color: #eee;
}

div.sublogin_dialog tr td {
	vertical-align: top;
	padding: 0.5em;
}

div.sublogin_dialog tr:nth-of-type(2n) {
	background-color: #eee;
}

div.sublogin_dialog table {
	width: 100%;
}

div.sublogin_dialog input.inputText {
	border: 1px inset #666666;
	margin: 1px;
	padding: 2px;
	font-size: 1.2em;
	width: 14em;
}

div.sublogin_dialog tr.sublogin_smallcel td {
	vertical-align: middle;
}

div.sublogin_dialog .sublogin_readonly input.inputText {
	border: none;
	background: none;
}

span.sublogins_login {
	font-size: 1.2em;
	font-weight: bold;
}

span.sublogins_master {
	font-style: italic;
}
span.sublogins_uitgeschakeld {
	font-weight: bold;
	color: maroon;
}

div.sublogin_listitem {
	background: url('/application/packages/bestel/resources/images/oplijst.png') no-repeat scroll 2px 2px transparent;
	border: 1px solid lightgray;
	border-radius: 0.3em;
	margin: 0.2em 0;
	padding: 0 0.4em 0 1.2em;
	max-width: 300px;
	background-color: #eee;
}

tr.sublogin_row:nth-of-type(2n) div.sublogin_listitem {
	background-color: #fff;
}

.verlof_errors p {
	color: red;
	text-align: left;
	width: 100% !important;
	margin: 10px 0 5px 0;
}
