.ui-tabs {
	.ui-tabs-nav {
		margin: inherit;
		padding: inherit;

		li {
			list-style: inherit;
			float: inherit;
			position: inherit;
			top: inherit;
			margin: inherit;
			border-bottom: inherit;
			padding: inherit;
			border-bottom: inherit;

			&.ui-tabs-active {
				margin-bottom: inherit;
				padding-bottom: inherit;
			}
		}
	}
}

.ui-widget-header {
	color: inherit;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-br {
	border-radius: inherit;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	border: inherit;
	background: inherit;
	font-weight: inherit;
	color: inherit;
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
	color: inherit;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
	border: inherit;
	background: inherit;
	font-weight: inherit;
	color: inherit;
}

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
	opacity: inherit;
}

.ui-button {
	display: inherit;
	position: inherit;
	overflow: inherit;
}

// .ui-state-default .ui-icon,
// .ui-widget-content .ui-icon {
// 	background-image: inherit;
// }

.ui-button-icon-only .ui-icon,
.ui-button-text-icon-primary .ui-icon,
.ui-button-text-icon-secondary .ui-icon,
.ui-button-text-icons .ui-icon,
.ui-button-icons-only .ui-icon {
	position: inherit;
	top: inherit;
	margin-top: inherit;
	left: inherit;
	margin-left: inherit;
}