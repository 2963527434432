.mijn_rapporten {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.mijn_rapporten-header {
	display: flex;
	justify-content: center;

	h1 {
		margin: 4rem 0 2rem 0;
		display: flex;
		flex-direction: column;
		color: $color-black-2;

		font-family: $font-heading;
		text-transform: uppercase;
		font-size: 4rem;
		font-weight: 600;

		&::after {
			content: '';
			height: 0.5rem;
			width: calc(100% + 4rem);
			background-color: $color-gold-3;
			transform: rotate(-1deg);
			margin: 0 0 0 -2rem;
		}
	}
}

#rapport_tabs {
	max-width: 160rem;
	width: 100%;

	.ui-tabs-nav {
		display: flex;
		margin: 2rem 0.5rem;
		justify-content: center;

		.ui-corner-top {
			padding: 1rem 0;
			margin: 0rem 2rem;

			a {
				font-size: 2rem;
				font-family: $font-heading;
				font-weight: 400;
				text-transform: uppercase;
				display: flex;
				flex-direction: column;
				color: $color-black-2;
			}

			&.ui-tabs-active {

				a {
					&::after {
						content: '';
						background-color: $color-gold-3;
						width: calc(100% + 2rem);
						margin: 0.5rem 0 0 -1rem;
						transform: rotate(-1deg);
						height: 0.5rem;
					}
				}
			}
		}
	}
}

#rapport_tab_rapport {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

#rapport_tab_rapport, #rapport_tab_datum {
	padding: 2rem 1rem;

	.rapport_type {
		padding: 2rem 0;
		margin: 0 1rem;
	}

	h2 {
		font-family: Oswald, sans-serif;
		font-weight: 400;
		text-transform: uppercase;
		margin: 0 2.5rem 2rem 2.5rem;
		font-size: 1.6rem;
	}

	.rapport {
		font-size: 1.4rem;
		font-weight: 300;
		padding: 1rem 2.5rem;
		font-family: $font-heading;
		display: flex;
		justify-content: space-between;
		border: 1px solid $color-grey-0;
		border-bottom: none;
		align-items: center;

		&:first-of-type {
			border-top-left-radius: $radius-default;
			border-top-right-radius: $radius-default;
		}

		&:last-of-type {
			border-bottom-left-radius: $radius-default;
			border-bottom-right-radius: $radius-default;
			border-bottom: 1px solid $color-grey-0;
		}

		&:hover {
			background-color: rgba($color-grey-0, 0.1);
		}

		&:nth-child(even) {
			background-color: rgba($color-grey-0, 0.2);

			&:hover {
				background-color: rgba($color-grey-0, 0.4);
			}
		}

		.rapport_nieuw, .rapport_update {
			text-transform: uppercase;
			font-weight: 400;
			font-size: 1.2rem;
		}

		.rapport_nieuw {
			color: red;
		}

		.rapport_update {
			color: orange;
		}
	}
}

#rapport_tab_datum {
	padding: 2rem;
}
