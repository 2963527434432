table {
	text-align: left;
	border: none;
	border-collapse: inherit;
	width: 100%;
	font-size: 1.6rem;
	color: $color-black-2;

	th, td {
		vertical-align: middle;
		text-align: left;
	}

	thead {
		border: none;
		line-height: 5rem;
		font-family: $font-heading;
		font-weight: 500;
		font-size: 2rem;
		text-transform: uppercase;
		text-align: left;
		padding: 2rem;

		tr {
			border-bottom: 1px solid $color-gold-3;

			th {
				font-weight: inherit;
				padding: 2rem 0 2rem 1rem;
				line-height: 1;

				&:first-of-type {
					padding-left: 2.4rem;
				}
			}
		}
	}

	tbody {
		font-family: $font-heading;
		font-weight: 300;

		.bestel_widget_status,
		.komend_tor,
		.bestel_lijst_status,
		.bestel_lijst_oorsprong {
			font-family: $font-main;
			font-size: 1.4rem;
			font-weight: 400;
		}

		.bestel_widget_leverdatum,
		.komend_naam,
		.spec__name,
		.spec__name,
		.bestel_lijst_leverdatum {
			font-weight: 400;
		}

		tr {
			padding: 2rem;
			background-color: white;

			&:hover {
				td {
					background-color: rgba($color-grey-0, 0.1);
				}
			}

			&:nth-child(even) {
				td {
					background-color: rgba($color-grey-0, 0.2);
				}

				&:hover {
					td {
						background-color: rgba($color-grey-0, 0.4);
					}
				}
			}

			&:first-of-type {
				td:first-of-type {
					border-top-left-radius: $radius-default;
				}

				td:last-of-type {
					border-top-right-radius: $radius-default;
				}
			}

			&:last-of-type {

				td {
					border-bottom: 1px solid $color-grey-0;
				}

				td:first-of-type {
					border-bottom-left-radius: $radius-default;
				}

				td:last-of-type {
					border-bottom-right-radius: $radius-default;
				}
			}

			td {
				padding: 1rem;
				border-top: 1px solid $color-grey-0;
				padding: 1.8rem 1rem;

				&:first-of-type {
					border-left: 1px solid $color-grey-0;
					padding-left: 2.4rem;
				}

				&:last-of-type {
					border-right: 1px solid $color-grey-0;
					padding-right: 2.4rem;
				}
			}
		}
	}

	// TODO: Fix after releasing 2.0
	.show-pdf--old {
		display: none;
	}

	.table__icon {
		width: 1.6rem;
		height: 1.6rem;
	}
}
