@import './../../variables.scss';

.contact {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 4rem 0 0 0;

	.contact__instructions {
		font-size: 1.6rem;
		max-width: 100rem;
		width: 100%;
		font-family: 'Open Sans';
		font-weight: 400;
		margin: 2rem 0;
	}

	.contact__forms {
		max-width: 100rem;
		width: 100%;

		.contact__form {
			width: 100%;
			margin: 0 0 1rem 0;
		}

		.form__content {
			padding: 0 0 0 4.7rem;
		}

		.button__submit {
			border: none;
			font-family: $font-heading;
			text-transform: uppercase;
			border-radius: $radius-default;
			background-color: $color-black-2;
			color: white;
			padding: 1rem 2.5rem;
			font-size: 1.6rem;
			cursor: pointer;
		}

		.form__label {
			font-family: $font-heading;
			font-weight: 400;
			font-size: 2rem;
			text-transform: uppercase;
			color: $color-black-2;
			padding: 0 0 1rem 0;
			display: flex;
			cursor: pointer;

			&::before {
				content: '\f054';
				font-family: 'Font Awesome 5 Free';
				font-weight: 600;
				margin: 0 1rem 0 0;
				width: 3.6rem;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			&.open::before {
				content: '\f078'
			}
		}

		.input__label {
			padding: 0;
			font-family: $font-heading;
			text-transform: uppercase;
			color: $color-black-2;
			font-size: 1.8rem;
			font-weight: 400;
		}

		input, textarea {
			font-family: $font-main;
			font-size: 1.6rem;
			padding: 2rem;
			border: 1px solid $color-grey-1;
			border-radius: $radius-default;
			transition: 0.3s $cubic-bezier-1 all;
			width: 100%;
			margin: 1rem 0;

			&[type="number"] {
				width: 8rem;
				text-align: center;
			}

			&:focus {
				border-color: #c1ad76;
			}
		}
	}
}
