.komend_row {
	border-bottom: 1px solid #CCC;
}

.komend_stuks {
	font-weight: 400;
}

.alt-row {
	//background-color: #EEE;
}

.komed_hidden {
	display: none;
}

.bestel_lijst_table_title {
	margin-top: 1em;
	padding: 10px 250px;
}

#bestel_lijst {
	width: 100%
}

.bestel_lijst_leverdatum, .bestel_lijst_referentie, .bestel_lijst_document {
	padding: 1px 3px
}

.bestel_lijst_pdf {
	width: 20px;
	height: 20px;
	margin-left: 3px
}

#portal_content .ui-button-icon-only .ui-button-text, #portal_content .ui-button-icons-only .ui-button-text {
	display: none;
}

.lever_vandaag, .lever_vandaag td {
	background-color: #FFC !important
}

.lever_done, .lever_done td {
	color: $color-black-2;

	.bestel_lijst_status {
		color: rgba($color-black-2, 0.5);
	}
}

#bestellijst-slider {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 2em;
	margin: 2em 0 0;
}

#bestellijst-slider .slider {
	position: absolute;
	left: 4em;
	right: 4em;
	bottom: .6em;
}

#bestellijst .slider-prev {
	position: absolute;
	left: 10px;
	bottom: 5px;
	height: 1.2em;
}

#bestellijst .slider-next {
	position: absolute;
	right: 10px;
	bottom: 5px;
	height: 1.2em;
}

#bestellijst-slider .tick {
	z-index: 1;
	position: absolute;
	height: 100%;
	font-size: 1.8em;
	line-height: 1.8em;
	background: none;
	border-top: none;
	border-right: none;
	border-bottom: none;
	text-align: center;
}

.ui-widget-content {
	border: none;
}

.ui-widget-header {
	background: none;
	border: none;
}

.order-history {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.order-history__header {
		display: flex;
		justify-content: center;

		h2 {
			margin: 4rem 0 2rem 0;
			display: flex;
			flex-direction: column;
			color: $color-black-2;

			.tr-bestel_historiek_title {
				font-family: $font-heading;
				text-transform: uppercase;
				font-size: 4rem;
				font-weight: 600;
			}

			&::after {
				content: '';
				height: 0.5rem;
				width: calc(100% + 4rem);
				background-color: $color-gold-3;
				transform: rotate(-1deg);
				margin: 0 0 0 -2rem;
			}
		}
	}

	.order-history__tabs {
		max-width: 160rem;
		width: 100%;

		.tabs__list {
			display: flex;
			margin: 2rem 0.5rem;
			justify-content: center;

			.list__item {
				padding: 1rem 0;
				margin: 0rem 2rem;

				.tab__item {
					font-size: 2rem;
					font-family: $font-heading;
					font-weight: 400;
					text-transform: uppercase;
					display: flex;
					flex-direction: column;
					color: $color-black-2;
				}

				&.ui-tabs-active {

					.tab__item {
						&::after {
							content: '';
							background-color: $color-gold-3;
							width: calc(100% + 2rem);
							margin: 0.5rem 0 0 -1rem;
							transform: rotate(-1deg);
							height: 0.5rem;
						}
					}
				}
			}
		}

		.tab__content {
			margin: 0 2rem;

			&.orders-products {
				& > p {
					font-family: $font-main;
					font-size: 1.4rem;
					margin: 2rem 0;
				}

				.products__filter {
					margin: 0 0 2rem 0;
					display: flex;

					.filter__radio {

						.radio__input {
							display: none;
						}

						.radio__description {
							font-family: $font-heading;
							text-transform: uppercase;
							font-weight: 400;
							color: $color-black-2;
							font-size: 1.6rem;
							display: flex;
							align-items: center;
							border: 1px solid $color-grey-0;
							border-radius: $radius-default;
							padding: 1rem 2.5rem;
							margin: 0 1rem 0 0;
							cursor: pointer;

							i {
								margin: 0 1rem 0 0;
							}
						}

						.radio__input:checked + .radio__description {
							background-color: $color-black-2;
							border-color: $color-black-2;
							color: white;
						}

						.radio__input:hover + .radio__description {
							border-color: $color-black-2;
						}
					}
				}

				.komend_naam {
					display: flex;
				}

				.eob-badge {
					margin: 0 0 0 1rem;
				}
			}
		}
	}
}



.bestel_lijst_table > thead > tr {

	th {
		font-family: $font-heading;
		text-transform: uppercase;
		font-size: 1.8rem;
		font-weight: 400;
		cursor: pointer;
	}
}
