* {
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
}

html {
	margin: 0;
	padding: 0;
	font-size: 10px;
}

body {
	display: flex;
	flex-direction: column;
	height: 100vh;
	//overflow: visible;
	//overflow-y: scroll;
	// TODO: Remove important after removing jquery-ui
	border: none !important;
	margin: 0;
}
